import { Auth, graphqlOperation } from 'aws-amplify';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import { toast } from 'react-toastify';
// import {  onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
 import { getMessaging, onMessage,getToken, isSupported } from 'firebase/messaging';
 import awsExports from './aws-exports';
import './index.css';
import { generateToken ,messaging} from './index';
import GoogleTagManager from './components/GoogleTagManager';
import { createDeviceTokenWebPush } from './graphql/mutations';
import { graphql } from './utils/api';
import { HStack, Text } from '@chakra-ui/react';

function App({ signOut }) {
  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (!session.isValid()) {
          console.log("signingOut")
          signOut();
        }
      })
      .catch(() => {
       // signOut();           // commemted bcaz we dont want signout to call on anonymous journey
      });
  }, []);
  // const openInDefaultBrowser = () => {
  //  const currenturl=  window.location.href  // Replace with your app's URL
  //  window.open(currenturl, '_blank', )
  // };
  // function isInAppBrowser() {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   console.log('userAgent', userAgent)
  //   return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1) || (userAgent.indexOf('Instagram') > -1);
  // }
  // let app
  // let messaging
  // if (isInAppBrowser()) {
  //   const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  //   let externalUrl 
  //   if (isIOS) 
  //     externalUrl = `x-safari-${window.location.href}`
  //   else 
  //   externalUrl = window.location.href
  //   toast(
  //     <HStack>
  //       <Text  fontSize={'sm'}>
  //       For an optimal experience, please use your device's standard browser. 
  //       </Text>
  //       <a href={externalUrl} target='_blank'
  //        style={{
  //         padding: '4px 8px', 
  //         backgroundColor: '#B72618', 
  //         color: '#fff', 
  //         borderRadius: '5px', 
  //         textDecoration: 'none', 
  //         textAlign: 'center',
  //         display: 'inline-block',
  //         width: '40%',
  //         // whiteSpace: 'nowrap',
  //         fontSize: '10px'
  //       }}
  //       download>Open in Browser</a>
        
  //     </HStack>,
  //     {
  //       position: 'top-right',
  //       autoClose: false, // Keeps the toast visible until user closes it or clicks the button
  //       closeOnClick: false,
  //     }
  //   );
   
  // }
  // if(!isInAppBrowser()){
  //  app =initializeApp({
  //   apiKey: 'AIzaSyDssrJjE3ONk-jk8EGdDToTOravMDfq-0c',
  //   authDomain: 'spirits-27586.firebaseapp.com',
  //   projectId: 'spirits-27586',
  //   storageBucket: 'spirits-27586.appspot.com',
  //   messagingSenderId: '438787698235',
  //   appId: '1:438787698235:web:54ebf9bfc53d266af0f61e',
  //   measurementId: 'G-PTS357JZ4T'
  // });
  // messaging = getMessaging(app);


  // }
//   if (isSupported()) {
//     console.log('Supported')
// } else {
//   console.log('no-support :(')
//   toast(`Firebase is Not supported ${messaging.isSupported()}`, {
//     position: 'top-right',
//     autoClose: 10000,
//     // onClick: () => {
//     //   navigate('/');
//     // },
//   });
// }
  // const generateToken=async()=>{
  //    let permission = Notification.permission
  //   if (!navigator.userAgent.includes('Edg')){
  //   permission = await Notification.requestPermission()
  //  Notification.requestPermission().then((permission) => {
  //   if (permission === 'granted') {
  //     console.log('Notification permission granted.');
  //   } else {
  //     console.log('Unable to get permission to notify.');
  //   }
  //  })
  // }
  // console.log('permission', permission)
  //  if (permission==='granted'){
  //   try{
  //     const token=await getToken(messaging, {
  //       vapidKey: awsExports.firebase_vapidKey,
  //     })
  //     console.log('token',token)
  //     if (token){
  //       const CreateDeviceTokenInput = {
  //         deviceToken: token,
  //         userPool: 'Customer',
  //         userType: 'Anonymous',
  //         status: 'Approved'
  //       };

  //       const graphqlQueryProduct = graphqlOperation(createDeviceTokenWebPush, {
  //         input: CreateDeviceTokenInput,
  //       });

  //       graphql({
  //         ...graphqlQueryProduct,
  //         authMode: 'API_KEY',
  //         authToken: awsExports.aws_appsync_apiKey,
  //       })
  //     }
  //   }
  //   catch(err){
  //     console.log('errorintoken',err)
  //     window.location.reload()
  //   }
    
  //  }
  
  // }
   
  
  // useEffect(()=>{
  //   if(!isInAppBrowser()){
  //   generateToken()
  //   onMessage(messaging, (payload) => {
  //      console.log('Foreground message received', payload);
      
  //     let notificationText = payload.notification.body;
  //     // const { orderId, orderStatus } = payload.notification.data;
    
  //     // if (payload.notification.type === 'Order') {
  //     //   notificationText = `Order ${orderId} got ${orderStatus} successfully`;
  //     // }
    
  //     toast(notificationText, {
  //       position: 'top-right',
  //       autoClose: 10000,
  //       // onClick: () => {
  //       //   navigate('/');
  //       // },
  //     });
  //   });
  // }
  // },[])

  return (
    <>
    <GoogleTagManager gtmId='GTM-ND8NLQD6'/>
      <Routes />
      <ToastContainer />
    </>
  );
}

const stateMapper = (state) => ({
  // user: state.auth.user,
});

const dispatchMapper = (dispatch) => ({
  // fetchAndSetUser: dispatch.auth.fetchAndSetUser,
  signOut: dispatch.auth.signOut,
});

export default connect(stateMapper, dispatchMapper)(App);
