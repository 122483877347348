export const getOrder = /* GraphQL */ `
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      cartId
      channel
      #closedAt
      #createdAt
      id
      orderShipment {
        items {
          actionType
          assignedStoreId
          assignedStoreName
          #createdAt
          deliveryAddress {
            addrLine1
            addrLine2
            city
            country
            latitude
            longitude
            postCode
            state
          }
          deliveryType
          id
          orderId
          orderLineItems {
            id
            prodShortDesc
            productId
            productName
            qtyPurchased
            size
            totalPrice
            unitPrice
            uom
            salePrice
          }
          shipmentStatus
          subOrderAmount
          subTotalDeliveryCharges
          subTotal1800platformfee
          subTotalCardProcessingFee
          shipmentLevelReplacement
          giftInstructions
          shipmentLevelReplacementFee
          totalPayableAmount
          subTotalAmount
          subTotalDiscount
          subTotalProductAmount
          subTotalSaleAmount
          discountSubTotalProductAmount
          discount
          subTotalTax
          subTotalTipAmount
          #updatedAt
          updatedBy
          userId
          promoCode_codeType
          expiryDateTime
          doordashInfo
        }
      }
      orderStatus
      totalAmount
      totalDeliveryCharges
      totalDiscount
      totalProductAmount
      totalTaxAmount
      totalTipAmount
      transactionId
      #updatedAt
      userAgent
      userId
    }
  }
`;

export const seachProductsElasic = /* GraphQL */ `
  query searchProducts($limit: Int, $filter: SearchableProductFilterInput) {
    searchProducts(filter: $filter, limit: $limit) {
      items {
        brandLine
        id
        prodName
        prodFullName
        images
      }
    }
  }
`;

export const getOrderByUserId = /* GraphQL */ `
  query getOrderByUserId(
    $userId: ID!
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByUserId(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        orderStatus
        orderShipment {
          items {
            actionType
            assignedStoreId
            deliveryType
            shipmentStatus
            assignedStoreName
            createdAt
            rejectionMsg
            deliveryAddress {
              addrLine1
              addrLine2
              city
              country
              latitude
              longitude
              postCode
              state
            }
            id
            orderId
            orderLineItems {
              id
              prodShortDesc
              productId
              productName
              qtyPurchased
              size
              totalPrice
              unitPrice
              uom
            }
            statusHistory {
              fromStatus
              toStatus
              updatedBy
              updatedAt
            }
            subOrderAmount
            subTotalDeliveryCharges
            subTotalMerchantCharges
            subTotal1800platformfee
            subTotalCardProcessingFee
            shipmentLevelReplacement
            giftInstructions
            shipmentLevelReplacementFee
            totalPayableAmount
            subTotalAmount
            subTotalDiscount
            subTotalProductAmount
            subTotalTax
            subTotalTipAmount
            updatedAt
            updatedBy
            userId
            doordashInfo
          }
          nextToken
        }
        cartId
        channel
        #closedAt
        createdAt
        id
        totalAmount
        totalDeliveryCharges
        totalDiscount
        totalProductAmount
        totalTaxAmount
        totalTipAmount
        transactionId
        updatedAt
        userAgent
        userId
      }
    }
  }
`;
export const listOrdersByUserId = /* GraphQL */ `
  query listOrdersByUserId(
    $userIdStatus: String!
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByUserId(
      userIdStatus:$userIdStatus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        orderStatus
        orderShipment {
          items {
            actionType
            assignedStoreId
            deliveryType
            shipmentStatus
            assignedStoreName
            createdAt
            rejectionMsg
            isUpdated
            messageStatus
            merchantAccountIdStatus
            deliveryAddress {
              addrLine1
              addrLine2
              city
              country
              latitude
              longitude
              postCode
              state
            }
            id
            orderId
            orderLineItems {
              id
              prodShortDesc
              productId
              productName
              qtyPurchased
              size
              totalPrice
              unitPrice
              uom
              storePosName
              storeSizeUom
              containerType
	            packUnits
              storeItemQty
              prodCategory
              storeDisplayQty
              imageSrc
              attributes
            }
            statusHistory {
              fromStatus
              toStatus
              updatedBy
              updatedAt
            }
            subOrderAmount
            subTotalDeliveryCharges
            subTotal1800platformfee
            subTotalCardProcessingFee
            shipmentLevelReplacement
            giftInstructions
            shipmentLevelReplacementFee
            totalPayableAmount
            subTotalAmount
            subTotalDiscount
            subTotalProductAmount
            discountSubTotalProductAmount
            discount
            appliedPromoCode
            promoCode_codeType
            expiryDateTime
            subTotalTax
            subTotalTipAmount
            updatedAt
            updatedBy
            userId
            doordashInfo
          }
          nextToken
        }
        cartId
        channel
        #closedAt
        createdAt
        id
        totalAmount
        totalDeliveryCharges
        totalDiscount
        totalProductAmount
        totalTaxAmount
        totalTipAmount
        transactionId
        updatedAt
        userAgent
        userId
      }
    }
  }
`;

export const listOrderShipmentsByUserId = /* GraphQL */ `
  query listOrderShipmentsByUserId(
    $userIdStatus: String!
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderShipmentsByUserId(
      userIdStatus:$userIdStatus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
            actionType
            assignedStoreId
            deliveryType
            shipmentStatus
            assignedStoreName
            createdAt
            rejectionMsg
            isUpdated
            messageStatus
            merchantAccountIdStatus
            deliveryAddress {
              addrLine1
              addrLine2
              city
              country
              latitude
              longitude
              postCode
              state
            }
            id
            orderId
            orderLineItems {
              id
              prodShortDesc
              productId
              productName
              qtyPurchased
              size
              totalPrice
              unitPrice
              uom
              storePosName
              storeSizeUom
              containerType
	            packUnits
              storeItemQty
              prodCategory
              storeDisplayQty
              imageSrc
              attributes
              upc
              storeItemId
            }
            statusHistory {
              fromStatus
              toStatus
              updatedBy
              updatedAt
            }
            subOrderAmount
            subTotalDeliveryCharges
            subTotal1800platformfee
            subTotalCardProcessingFee
            shipmentLevelReplacement
            giftInstructions
            shipmentLevelReplacementFee
            totalPayableAmount
            subTotalAmount
            subTotalDiscount
            subTotalProductAmount
            discountSubTotalProductAmount
            discount
            appliedPromoCode
            promoCode_codeType
            expiryDateTime
            subTotalTax
            subTotalTipAmount
            updatedAt
            updatedBy
            userId
            doordashInfo
          }
    }
  }
`;

export const searchStores = /* GraphQL */ `
  query searchStores(
    $filter: SearchableStoreFilterInput
    $lat: Float!
    $lon: Float!
    $distance: Int!
  ) {
    searchStores(filter: $filter, lat: $lat, lon: $lon, distance: $distance) {
      items {
        id
        storeName
        storePhotos
        scheduleHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        deliveryHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        businessHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        address {
          addrLine1
          addrLine2
          city
          latitude
          longitude
          postCode
          state
        }
        deliveryScope {
          deliveryType
          MinOrderSize
        }
        deliveryFee
        creditCardProcessingPercent
        creditCardProcessingFlatFee
        merchantFeeToCustomer
      }
    }
  }
`;

export const searchStore = /* GraphQL */ `
  query searchStore(
    $filter: SearchableStoreFilterInput
    $lat: Float!
    $lon: Float!
    $distance: Int!
    $showAll: Boolean
  ) {
    searchStore(
      filter: $filter
      lat: $lat
      lon: $lon
      distance: $distance
      showAll: $showAll
    ) {
      items {
        id
        storeName
        storePhotos
        merchantAccountId
        isPickupOutOfState
        scheduleHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        deliveryHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        businessHours {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
        }
        address {
          addrLine1
          addrLine2
          city
          latitude
          longitude
          postCode
          state
        }
        deliveryScope {
          serviceArea
          deliveryType
          deliveryPartner
          MinOrderSize
        }
        deliveryFee
        deliveryFeeConfig
        {
          from
          to
          deliveryFee
        }
        automaticDiscountConfig{
          category
          size
          uom
          quantity
          discount
          discountFlatAmount
        }
        storePhoneNumber
        automaticDiscountFlag
        storePhoneNumber
        creditCardProcessingPercent
        creditCardProcessingFlatFee
        merchantFeeToCustomer
        orderFulfilling {
          pickUp
          delivery
          gifting
        }
        isDeliveryPaused
        isPickupPaused
      }
    }
  }
`;

export const getCart = /* GraphQL */ `
  query getCart($id: ID!) {
    getCart(id: $id) {
      anonymousId
      belongsTo
      channel
      id
      orderStatus
      totalAmount
      totalDeliveryCharges
      totalDiscount
      totalProductAmount
      totalTaxAmount
      totalTipAmount
      transactionId
      userAgent
      userId
      cartShipment {
        nextToken
        items {
          assignedStoreId
          assignedStoreName
          cartId
          deliveryType
          deliveryFee
          creditCardProcessingPercent
          creditCardProcessingFlatFee
          id
          shipmentStatus
          subOrderAmount
          subTotalDeliveryCharges          
          subTotal1800platformfee
          subTotalCardProcessingFee
          merchantFeeToCustomer
          subTotalAmount
          subTotalDiscount
          subTotalProductAmount
          discountSubTotalProductAmount
          subTotalTax
          subTotalTipAmount
          updatedBy
          userId
          discount
          autoDiscount
          saleDiscount
          deliveryAddress {
            addrLine1
            addrLine2
            city
            country
            latitude
            longitude
            postCode
            state
          }
          lineItems {
            prodCategory
            id
            imageSrc
            prodShortDesc
            productId
            productName
            qtyPurchased
            size
            itemInvalid
            totalPrice
            salePrice
            unitPrice
            uom
            upc
            storeItemId
            storeItemDesc
            storePosName
            storeSizeUom
            storeDisplayQty
            availableQty
            remainingAttributes
            attributes
            discountQtyPurchased
            discountUnitPrice
            discountTotalPrice
            sortedData
            totalSalePrice
            discount
          }
        }
      }
    }
  }
`;

export const getCartByUserId = /* GraphQL */ `
  query getCartByUserId($userId: String!) {
    getCartByUserId(userId: $userId) {
      items {
        id
      }
    }
  }
`;

export const getStore = /* GraphQL */ `
  query getStore($id: ID!) {
    getStore(id: $id) {
      businessHours {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
      }
      deliveryHours {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
      }
      address {
        addrLine1
        addrLine2
        city
        latitude
        longitude
        postCode
        state
        }
      isPickupOutOfState
      storePhotos
      aboutStore
      scheduleHours {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
      }
      deliveryFee
      deliveryFeeConfig
      {
        from
        to
        deliveryFee
      }
      automaticDiscountConfig{
        category
        size
        uom
        quantity
        discount
        discountFlatAmount
      }
      merchantAccountId
      storeName
      storePhoneNumber
      automaticDiscountFlag
      merchantFeeToCustomer
      destinationAccountID
      creditCardProcessingPercent
      creditCardProcessingFlatFee
      isDeliveryPaused
      isPickupPaused
      deliveryScope {
          serviceArea
          deliveryType
          MinOrderSize
          deliveryPartner
        }
      merchantFeeConfig {
      from
      to
      merchantFee
      merchantFeeToCustomer
      }
      orderFulfilling {
        pickUp
        delivery
        gifting
      }
    }
  }
`;

export const searchStoresByProductId = /* GraphQL */ `
  query searchPriceAndAvailabilitys(
    $filter: SearchablePriceAndAvailabilityFilterInput
    $lat: Float!
    $lon: Float!
    $distance: Int!
  ) {
    searchPriceAndAvailabilitys(
      filter: $filter
      lat: $lat
      lon: $lon
      distance: $distance
    ) {
      items {
        id
        storeId
        price
        storeItemId
        storeItemDesc
      }
    }
  }
`;

export const searchAddress = /* GraphQL */ `
  query searchAddress($input: SearchAddressInput!) {
    searchAddress(input: $input) {
      items {
        city
        entries
        secondary
        state
        street_line
        zipcode
      }
    }
  }
`;
export const searchProductsLambda = /* GraphQL */ `
  query searchProductsLambda(
    $filter: SearchableProductFilterInput
    $limit: Int
    $searchType: ProductSearchType
    $bucketFilter: BucketFilterInput
    $nextToken: String
    $distance: Int
    $from: Int
    $lat: Float
    $lon: Float
    $maxPrice: Float
    $minPrice: Float
    $sort: SearchableProductSortInput
  ) {
    searchProductsLambda(
      filter: $filter
      limit: $limit
      searchType: $searchType
      bucketFilter: $bucketFilter
      nextToken: $nextToken
      distance: $distance
      from: $from
      lat: $lat
      lon: $lon
      maxPrice: $maxPrice
      minPrice: $minPrice
      sort: $sort
    ) {
      items {
        prodFullName
        prodCategory
        brandLine
        manufacturer
        prodMinor
        region
        abv
        price
        imageFile
      }
      nextToken
      Brand {
        key
        doc_count
        name_count {
          value
        }
      }
      ProdCategory {
        key
        doc_count
        name_count {
          value
        }
      }
      Manufacturer {
        key
        doc_count
        name_count {
          value
        }
      }
      ProdMajor {
        key
        doc_count
        name_count {
          value
        }
      }
      ProdMinor {
        key
        doc_count
        name_count {
          value
        }
      }
      Country {
        key
        doc_count
        name_count {
          value
        }
      }
      Container {
        key
        doc_count
        name_count {
          value
        }
      }
      Size{
        key
        doc_count
        name_count {
          value
        }
      }
      Uom{
        key
        doc_count
        name_count {
          value
        }
      }
      Abv{
        key
        doc_count
        name_count {
          value
        }
      }
      Ibu{
        key
        doc_count
        name_count {
          value
        }
      }
      SizeUom{
        key
        doc_count
        name_count {
          value
        }
      }
      Age{
        key
        doc_count
        name_count {
          value
        }
      }
      Region{
        key
        doc_count
        name_count {
          value
        }
      }
      State{
        key
        doc_count
        name_count {
          value
        }
      }
      Varietal{
        key
        doc_count
        name_count {
          value
        }
      }
      Appellation{
        key
        doc_count
        name_count {
          value
        }
      }
      ProdFullName {
        key
        doc_count
        upcList
        priceRange    
        imageList
        categoryList       
      }
      total
    }
  }
`;

export const searchProducts = /* GraphQL */ `
  query searchProducts(
    $filter: SearchableProductFilterInput
    $searchType: ProductSearchType
    $limit: Int
    $nextToken: String
    $sort: SearchableProductSortInput
  ) {
    searchProducts(filter: $filter, limit: $limit, nextToken: $nextToken, searchType: $searchType,sort: $sort) {
      items {
        id
        prodFullName
        prodName
        prodCategory
        imageFile
        brandLine
        manufacturer
        prodMinor
        region
        abv
      }
      nextToken
      brand {
        key
        doc_count
      }
      ProdCategory {
        key
        doc_count
      }
      manufacturer {
        key
        doc_count
      }
      ProdMajor {
        key
        doc_count
      }
      ProdMinor {
        key
        doc_count
      }
      majorType {
        key
        doc_count
      }
      country {
        key
        doc_count
      }
      container {
        key
        doc_count
      }
      total
    }
  }
`;

export const searchProductsGroups = /* GraphQL */ `
  query searchProductsGroups(
    $filter: SearchableProductGroupFilterInput
    $bucketFilter: BucketFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsGroups(filter: $filter, bucketFilter: $bucketFilter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        prodFullName
        prodCategory
        brandLine
        manufacturer
        prodMinor
        country
      }
      Brand {
        key
        doc_count
      }
      Container{
        key
        doc_count
      }
      Country{
        key
        doc_count
      }
      Manufacturer{
        key
        doc_count
      }
      ProdMinor{
        key
        doc_count
      }
      ProdCategory{
        key
        doc_count
      }
      ProdMajor{
        key
        doc_count
      }
      Size{
        key
        doc_count
      }
      Uom{
        key
        doc_count
      }
      Abv{
        key
        doc_count
      }
      Ibu{
        key
        doc_count
      }
      SizeUom{
        key
        doc_count
      }
      Age{
        key
        doc_count
      }
      Region{
        key
        doc_count
      }
      State{
        key
        doc_count
      }
      Varietal{
        key
        doc_count
      }
      Appellation{
        key
        doc_count
      }
      nextToken
      total
    }
  }
`;

export const searchProductsMaster = /* GraphQL */ `
  query searchProductsMaster(
    $filter: SearchableProductMasterFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsMaster(filter: $filter,sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      Brand {
        key
        doc_count
      }
      FullName {
        key
        doc_count
      }
      items {
        age
        id
        prodFullName
        prodName
        prodCategory
        imageFile
        brandLine
        manufacturer
        prodMinor
        region
        abv
        country
        size
        uom
        container
        sizeUom
        packUnits
        containerType
        prodDisplayName
        prodMajor
      }
      nextToken
      total
    }
  }
`;

export const searchProductsCatalogue = /* GraphQL */ `
  query searchProductsCatalogue(
    $filter: SearchableProductCatalogueFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductsCatalogue(filter: $filter,sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      Brand {
        key
        doc_count
      }
      FullName {
        key
        doc_count
      }
      items {
        age
        id
        prodFullName
        feature
        aboutProducer
        producer
        rawMaterials
        productDescription
        prodName
        prodCategory
        imageFile
        brandLine
        manufacturer
        prodMinor
        region
        abv
        country
        size
        uom
        container
        sizeUom
        packUnits
        containerType
        prodDisplayName
        prodMajor
      }
      nextToken
      total
    }
  }
`;

export const searchPriceNAvailabilitys = /* GraphQL */ `
  query searchPriceNAvailabilitys(
    $id:  [String!]
    $sort: SearchablePriceAndAvailabilitySortInput
    $limit: Int
    $nextToken: String
    $from: Int
    $lat: Float
		$lon: Float
		$distance: Int
  ) {
    searchPriceNAvailabilitys(id: $id, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from, lat: $lat, lon: $lon, distance: $distance) {
      items {
        id
        storeId
        price
        availableQty
        storeItemId
        storeItemLevel
        storeItemQty
        storeItemVintage
        sizeUom
        productName
        salePrice
        packType
      }
      nextToken
      total
    }
  }
`;

export const getMultipleSignedURL = /* GraphQL */ `
  query getMultipleSignedURL(
    $filesList: [S3FileType]!
    $merchantAccountId: String!
    $requestType: String!
    $storeID: String
    $createdBy:String
  ) {
    getMultipleSignedURL(
      filesList: $filesList
      merchantAccountId: $merchantAccountId
      requestType: $requestType
      storeID: $storeID
      createdBy:$createdBy
      ) {
      items {
        fileName
        signedURL
      }
    }
  }
`;

export const searchPriceNAvailabilitysListTemp = /* GraphQL */ `
  query searchPriceNAvailabilitysListTemp(
    $filter: SearchablePriceNAvailabilityFilterInput
    $bucketFilter:BucketFilterInput
    $sort: SearchablePriceAndAvailabilitySortInput
    $limit: Int
    $nextToken: String
    $from: Int
    $lat: Float
		$lon: Float
		$distance: Int
    $searchType: ProductSearchType
  ) {
    searchPriceNAvailabilitysListTemp(filter: $filter, bucketFilter: $bucketFilter, sort: $sort , limit: $limit , nextToken: $nextToken, from: $from,lat: $lat,lon: $lon, distance: $distance, searchType: $searchType) {
      items {
        id
        storeId
        storeName
	address{
    addrLine1
    }
	businessHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
	deliveryHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
  scheduleHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
	orderFulfilling {
    pickUp
    delivery
    gifting
  }
	deliveryScope {
    MinOrderSize
  }
  deliveryFeeConfig
  {
    from
    to
    deliveryFee
  }
	automaticDiscountConfig{
    category
    size
    uom
    quantity
    discount
    discountFlatAmount
  }
	automaticDiscountFlag
	creditCardProcessingPercent
	creditCardProcessingFlatFee
	merchantFeeToCustomer
	isDeliveryPaused
	isPickupPaused
        price
        availableQty
        storeItemId
        storeItemLevel
        storeItemQty
        storeItemVintage
        sizeUom
        productName
        salePrice
        packType
        prodCategory
	prodMajor
	prodMinor
	productBrandRef
	manufacturer
	brandLine
	prodName
	prodFullName
	container
	containerFullDesc
	containerType
	ibu
	packUnits
	size
	uom
	country
	region
	appellation
	vintage
	varietal
	abv
	imageFile
  discountAmount
	discountPercent
      }
      Brand {
        key
        doc_count
      }
      Container{
        key
        doc_count
      }
      Country{
        key
        doc_count
      }
      Manufacturer{
        key
        doc_count
      }
      ProdMinor{
        key
        doc_count
      }
      ProdCategory{
        key
        doc_count
      }
      ProdMajor{
        key
        doc_count
      }
      Size{
        key
        doc_count
      }
      Uom{
        key
        doc_count
      }
      Abv{
        key
        doc_count
      }
      Ibu{
        key
        doc_count
      }
      SizeUom{
        key
        doc_count
      }
      Age{
        key
        doc_count
      }
      Region{
        key
        doc_count
      }
      State{
        key
        doc_count
      }
      Varietal{
        key
        doc_count
      }
      Appellation{
        key
        doc_count
      }
      DiscountAmount{
        key
        doc_count
      }
      DiscountPercent{
        key
        doc_count
      }
      nextToken
      total
    }
  }
`;

export const searchPriceNAvailabilityQueries = /* GraphQL */ `
  query searchPriceNAvailabilityQueries(
    $filter: SearchablePriceNAvailabilityFilterInput
    $bucketFilter:BucketFilterInput
    $sort: SearchablePriceAndAvailabilitySortInput
    $limit: Int
    $nextToken: String
    $from: Int
    $lat: Float
		$lon: Float
		$distance: Int
    $searchType: ProductSearchType
  ) {
    searchPriceNAvailabilityQueries(filter: $filter, bucketFilter: $bucketFilter, sort: $sort , limit: $limit , nextToken: $nextToken, from: $from,lat: $lat,lon: $lon, distance: $distance, searchType: $searchType) {
      items {
        id
        storeId
        storeName
	address{
    addrLine1
    }
	businessHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
	deliveryHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
  scheduleHours {
    Mon
    Tue
    Wed
    Thu
    Fri
    Sat
    Sun
  }
	orderFulfilling {
    pickUp
    delivery
    gifting
  }
	deliveryScope {
    MinOrderSize
  }
  deliveryFeeConfig
  {
    from
    to
    deliveryFee
  }
	automaticDiscountConfig{
    category
    size
    uom
    quantity
    discount
    discountFlatAmount
  }
	automaticDiscountFlag
	creditCardProcessingPercent
	creditCardProcessingFlatFee
	merchantFeeToCustomer
	isDeliveryPaused
	isPickupPaused
        price
        availableQty
        storeItemId
        storeItemLevel
        storeItemQty
        storeItemVintage
        sizeUom
        productName
        salePrice
        packType
        prodCategory
	prodMajor
	prodMinor
	productBrandRef
	manufacturer
	brandLine
	prodName
	prodFullName
	container
	containerFullDesc
	containerType
	ibu
	packUnits
	size
	uom
	country
	region
	appellation
  feature
  producer
  rawMaterials
	vintage
	varietal
	abv
	imageFile
  seqNum
  discountAmount
	discountPercent
      }
      Brand {
        key
        doc_count
      }
      Container{
        key
        doc_count
      }
      Country{
        key
        doc_count
      }
      Manufacturer{
        key
        doc_count
      }
      ProdMinor{
        key
        doc_count
      }
      ProdCategory{
        key
        doc_count
      }
      ProdMajor{
        key
        doc_count
      }
      Size{
        key
        doc_count
      }
      Uom{
        key
        doc_count
      }
      Abv{
        key
        doc_count
      }
      Ibu{
        key
        doc_count
      }
      SizeUom{
        key
        doc_count
      }
      Age{
        key
        doc_count
      }
      Region{
        key
        doc_count
      }
      State{
        key
        doc_count
      }
      Varietal{
        key
        doc_count
      }
      Appellation{
        key
        doc_count
      }
      DiscountAmount{
        key
        doc_count
      }
      DiscountPercent{
        key
        doc_count
      }
      Stores{
        key
        doc_count
      }
      AvailableQuantity{
        key
        doc_count
      }
      Feature{
        key
        doc_count
      }
      Producer{
        key
        doc_count
      }
      Vintage{
        key
        doc_count
      }
      RawMaterials{
        key
        doc_count
      }
      nextToken
      total
    }
  }
`;

export const searchProductsByCategory = /* GraphQL */ `
  query searchProducts(
    $filter: SearchableProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prodFullName
        prodName
        prodCategory
        imageFile
        brandLine
        manufacturer
        prodMinor
        region
        abv
      }
      nextToken
    }
  }
`;

export const getCustomerProfile = /* GraphQL */ `
  query getCustomerProfile($userId: ID!) {
    getCustomerProfile(userId: $userId) {
      id
      phoneNumber
      firstName
      lastName
      middleName
      myReferralCode
      email
      userId
      orderId
      profileImage
      deliveryToId
      referredCode
      referredCustomerUserId
      customerContact {
        items {
          email
          id
          phoneNumber
          lastName
          firstName
        }
      }
      deliveryToAddress {
        customerContactId
        firstName
        lastName
        addressType
        id
        latitude
        longitude
        addrLine1
        addrLine2
        city
        addrState
        country
        postCode
      }
      subscribeToNotification
      customerId
      promoCodes{
        promoCode
	      issued
	      used
	      issuedBy
	      codeDescription
	      codeApplicability
	      codeCategory
        issuedDate
        expiryDate
        maxCouponValue
        categoryType
        expiryDateTime
        promoCode_codeType
      }
    }
  }
`;

export const getPromoCode = /* GraphQL */ `
  query getPromoCode($promoCode: String!) {
    getPromoCode(promoCode: $promoCode) {
      items {
      categoryType
      codeType
      issuedByCustomerId
      promoCode
      expiryDateTime
      expiryDate
      }
    }
  }
`;

export const getCustomerPaymentByCustomerProfileId = /* GraphQL */ `
  query CustomerPaymentByCustomerProfileId($userId: ID!) {
    CustomerPaymentByCustomerProfileId(userId: $userId) {
      items {
        id
        bankName
        cardHolderName
        cardNumber
        expDate
        postalCode
        cardDefault
        userId
        customerId
      }
    }
  }
`;

export const getCustomerContacts = /* GraphQL */ `
  query CustomerContactsByCustomerProfileId(
    $userId: ID!
    $filter: ModelCustomerContactFilterInput
  ) {
    CustomerContactsByCustomerProfileId(userId: $userId, filter: $filter) {
      items {
        id
        contactCategory
        contactCustomType
        firstName
        lastName
        middleName
        phoneNumber
        email
        defaultAddressId
        deliveryAddress {
          items {
            id
            addrLine1
            addrLine2
            addressType
            firstName
            city
            instructions
            lastName
            markDefault
            middleName
            phoneNumber
            postCode
            addrState
            customType
            country
            latitude
            longitude
          }
        }
        occasions {
          items {
            occasionDate
            occasionTitle
            reminder
            id
          }
        }
      }
    }
  }
`;

export const getCustomerOccasions = /* GraphQL */ `
  query CustomerOccasionByCustomerContactId($id: ID!) {
    CustomerOccasionByCustomerContactId(id: $id) {
      items {
        id
        occasionTitle
        occasionDate
        reminder
        customerContactId
      }
    }
  }
`;

export const getCustomerAddresses = /* GraphQL */ `
  query CustomerAddressByCustomerContactId($id: ID!) {
    CustomerAddressByCustomerContactId(id: $id) {
      items {
        id
        instructions
        addrLine1
        addrLine2
        addressType
        city
        firstName
        lastName
        phoneNumber
        middleName
        postCode
        addrState
        markDefault
      }
    }
  }
`;

export const getS3SignedURL = /* GraphQL */ `
  query getS3SignedURL(
    $contentType: String!
    $fileName: String!
    $userId: String!
    $requestType: String!
  ) {
    getS3SignedURL(
      contentType: $contentType
      fileName: $fileName
      userId: $userId
      requestType: $requestType
    ) {
      fileName
      signedURL
    }
  }
`;
export const onRejectionNotificationUpdate = /* GraphQL */ `
  subscription onRejectionNotificationUpdate($userId: ID!) {
    onRejectionNotificationUpdate(userId: $userId) {
      rejectionMsg
      orderId
      id
    }
  }
`;

export const onUpdateOrderShipmentNotification = /* GraphQL */ `
  subscription onUpdateOrderShipmentNotification($userId: ID!) {
    onUpdateOrderShipmentNotification(userId: $userId) {
      orderId
      id
      shipmentStatus
    }
  }
`;
export const onReplaceOrderShipmentNotification = /* GraphQL */ `
  subscription onReplaceOrderShipmentNotification($userId: ID) {
    onReplaceOrderShipmentNotification(userId: $userId) {
      messageStatus
    }
  }
`;
export const onAddOrderShipmentWebhook = /* GraphQL */ `
  subscription onAddOrderShipmentWebhook($dropoff_phone_number: String!) {
  onAddOrderShipmentWebhook(dropoff_phone_number: $dropoff_phone_number) {
  event_name
	external_delivery_id
	dasher_id
	dasher_name
	dasher_phone_number
	dasher_dropoff_phone_number
	dasher_pickup_phone_number
	pickup_address
	pickup_phone_number
	pickup_instructions
	pickup_external_business_id
	pickup_external_store_id
	dropoff_address
	dropoff_phone_number
	dropoff_instructions
	dropoff_contact_given_name
	dropoff_contact_family_name
	dropoff_contact_send_notifications
	order_value
	currency
	pickup_time_estimated
	dropoff_time_estimated
	fee
	tip
	support_reference
	tracking_url
	contactless
  shipmentStatus
    }
  }
`;

export const onAddStripeWebhook = /* GraphQL */ `
  subscription onAddStripeWebhook($email: String) {
  onAddStripeWebhook(email: $email) {
  email
  payment_intent_id
  amount_capturable
  amount_received
  stripe_event_type
  deliverytype
    }
  }
`;

export const getPromotions = /* GraphQL */ `
  query getPromotions {
    listCarouselData {
      imageUrl
      name
      tags {
        Key
        Value
      }
    }
  }
`;

export const getAllPromoCodes = /* GraphQL */ `
query getAllPromoCodes($id: ID!,$userId: ID!) {
    getAllPromoCodes(id: $id,userId:$userId) {
      Items {
        codeType
        promoCode
	      codeDescription
	      maxCouponValue
        codeApplicability
        codeCategory
        issuedDate
        expiryDate
        categoryType
        promoCode_codeType
        expiryDateTime
      }
    }
  }
`;